* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'EB Garamond', 'Lora', 'Merriweather', 'Playfair Display', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --nav-height: 80px;
  --font-color: rgb(228, 228, 228);
  --sec-font-color: rgb(92, 113, 128);
  --props-color: #e4e4e4;
  --secondary-props-color: rgb(194, 194, 194);
  --nav-background-color: #0D1821;
  --background-color: #102231; /* #0D1821; */
  --background-sec-color: #182c3a; /* rgb(17, 32, 43); */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Scrollbar look */
::-webkit-scrollbar-track {
  background: #0d151d; 
}
 
::-webkit-scrollbar-thumb {
  background: #213e52; 
  /* border: solid 1px #101a22; */
}

::-webkit-scrollbar-thumb:hover {
  background: #294c63; 
}

h2 {
  font-size: 3em;
  /* text-transform: uppercase; */
  color: var(--font-color);
}

/* ---------------------------- Navigation bar ---------------------------- */

nav {
  position: fixed;
  top: 0;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: var(--nav-height);
  background: transparent;
  background-color: rgba(0, 0, 0, 0.4);
  transition-duration: 0.5s;
  transition-property: all;
}

nav.active {
  background: var(--nav-background-color);
  transition-duration: 0.5s;
}

.nav-border {
  height: 2px;
  width: 100%;
  position: fixed;
  top: var(--nav-height);
  background: var(--props-color);
  transition-duration: 1s;
}

.nav-border.active {
  background: var(--background-sec-color);
}

.nav-menu {
  display: flex;
  justify-content: space-between;
  width: 45%;
  list-style: none;
  font-size: 1.2em;
  margin: 0 40px;
}

.nav-menu li a {
  padding: 10px;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
  color: var(--font-color);
}

nav .sun {
  width: 140px;
  height: 60%;
  /* margin-right: 7px; */

  background-repeat: no-repeat;
}

nav .sun:nth-child(2) {
  transform: scaleX(-1);
  /* margin-left: 7px; */
  margin-right: 0px;
}

nav .zigzag {
  width: 32.5%;
  height: 40%;

  background-repeat: repeat-x;
}

nav .zigzag:first-child {
  background-position: right;
}

nav .zigzag:nth-child(5) {
  background-position: left;
}

.burger {
  display: none;
  cursor: pointer;
}

nav img {
  display: none;
}

.burger div {
  width: 25px;
  height: 3px;
  background-color: var(--props-color);
  margin: 5px;
  transition: transform 0.3s ease-in;
}

/* ---------------------------- Home Page ---------------------------- */

.homepage {
  width: 100%;
  height: 100vh;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage img {
  max-width: 500px;
  margin-top: 20vh;
  margin-bottom: auto;
}

.homepage a {
  margin-bottom: 8vh;
}

.scroll-down {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scroll-down .line {
  width: 150px;
  height: 4px;
  background-color: var(--props-color);
  margin: 5px;

  transform: translateX(0%);
  transition: 0.5s ease-in;
}

.scroll-down:hover .line {
  transition: 0.5s ease-out;
}

.scroll-down:hover .line:first-child {
  transform: translateX(-15%);
}

.scroll-down:hover .line:last-child {
  transform: translateX(15%);
}

.scroll-down .arrows {
  display: flex;

  transform: translateX(0%);
  transition: 0.5s ease-in;
}

.scroll-down .arrows svg {
  width: 45px;
  margin: 0 20px;

  fill: var(--props-color);
}

.scroll-down:hover .arrows {
  transform: translateY(25%);
  transition: 0.5 ease-in;
}

/* ---------------------------- About Page ---------------------------- */

.aboutpage {
  height: 100vh;
  width: 100%;

  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  border-top: 1px solid var(--background-sec-color);
  border-bottom: 1px solid var(--background-sec-color);
}

.aboutpage .tree {
  width: 50vw;
  max-width: 550px;
  margin-bottom: 5vh;
}

.aboutpage h2 {
  opacity: 0;
  transform: translateY(100%);

  margin-top: 18vh;
}

.aboutpage.active h2 {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

.aboutpage div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.aboutpage .bar {
  display: block;
  height: 40vh;
  margin: 0 5vw;
}

.aboutpage p {
  width: 50%;
  text-align: center;
  color: var(--font-color);
  font-size: 1.2em;
  line-height: 1.6em;

  opacity: 0;
  transform: translateY(100%);
}

.aboutpage.active p {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

@media screen and (max-width: 950px) {
  .aboutpage h2 {
    color: var(--font-color);
    margin-bottom: 20px;
    font-size: 3em;
    transform: translateY(50%);
  }

  .aboutpage p {
    width: 80%;
    margin-bottom: 10px;
    transform: translateY(50%);
  }

  .aboutpage .bar {
    display: none;
  }

  .aboutpage div {
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .aboutpage .tree {
    width: 70vw;
  }
}

/* ---------------------------- History Tab Page ---------------------------- */

.history-tab-page {
  height: 100vh;
  width: 100%;

  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid var(--background-sec-color);
  border-bottom: 1px solid var(--background-sec-color);

  overflow: hidden;
}

.history-tab-page h2 {
  margin-bottom: 60px;
  margin-top: 15vh;

  opacity: 0;
  transform: translateY(100%);
}

.history-tab-page.active h2 {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}


.history-tab-container {
  width: 70vw;
  height: 60vh;

  display: flex;
  opacity: 0;
  transform: translateY(25%);
}

.history-tab-page.active .history-tab-container {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

.tab-content {
  width: 75%;
  height: 100%;
  background-color: var(--background-sec-color);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tab-content h3 {
  padding: 0 20px;
  padding-top: 20px;

  text-align: left;
  color: var(--font-color);
  font-size: 2em;
}

.tab-content h4 {
  padding: 0 20px;
  padding-bottom: 20px;

  text-align: left;
  color: var(--sec-font-color);
  font-size: 1.5em;
  font-weight: normal;
}

.tab-content .content {
  padding: 0 20px;
  padding-bottom: 20px;

  width: 100%;

  display: flex;
  overflow: hidden;
}

.tab-content p {
  margin-right: 20px;
  flex: 1 0 45%;

  text-align: left;
  color: var(--font-color);
  font-size: 1.2em;
  line-height: 1.6em;

  overflow: auto;
}

.tab-content .content .wrap {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.tab-content .content img {
  object-fit: cover;
  height: 100%;
}

.tabs {
  width: 25%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabs button {
  width: 100%;
  height: 100%;
  background-color: var(--background-color);

  font: inherit;
  background: none;
	border: 1px solid var(--background-sec-color);
	padding: 0;
	cursor: pointer;
  outline: inherit;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tabs button.active {
  background-color: var(--background-sec-color);
}

.tabs h3 {
  margin: 0 10px;
  font: inherit;
  font-weight: normal;
  font-size: 1.7em;
  margin-bottom: 10px;
  color: var(--font-color);
}

.tabs h4 {
  font-weight: normal;
  font-size: 1.15em;
  color: var(--sec-font-color);
}

/* ---------------------------- Gallery Page ---------------------------- */

.gallery-page {
  height: 100vh;
  width: 100%;

  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  align-items: center;

  border-top: 1px solid var(--background-sec-color);
  border-bottom: 1px solid var(--background-sec-color);
}

.gallery-page h2 {
  margin-top: 15vh;
  margin-bottom: 60px;

  opacity: 0;
  transform: translateY(100%);
}

.gallery-page.active h2 {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

.image-container {
  width: 70%;

  display: flex;
  justify-content: center;
  align-items: center;

  flex-wrap: wrap;

  opacity: 0;
  transform: translateY(25%);
}

.gallery-page.active .image-container {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}


.image-wrapper {
  margin: 1px;
  width: 17vw;
  height: 21vh;

  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  cursor: pointer;
}

.image-wrapper img {
  max-height: inherit;
  max-width: inherit;
  height: inherit;
  width: inherit;
  background: none;

  object-fit: cover;
}

.modal {
  z-index: 50;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;

  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal img {
  max-width: 70%;
  max-height: 80%;
}

.modal button {
  height: 75px;
  width: 75px;
  /* background-color: red; */

  background: none;
	color: var(--sec-font-color);
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.img-back {
  margin-left: 60px;
}

.img-forward {
  margin-right: 60px;
}

.modal button svg {
  width: 100%;
  height: 100%;
  fill: var(--secondary-props-color);
}

.modal svg:hover {
  fill: var(--props-color);
}

/* ---------------------------- Contact Page ---------------------------- */

.contact-page {
  height: 100vh;
  width: 100%;

  background-color: var(--background-color);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid var(--background-sec-color);
  border-bottom: 1px solid var(--background-sec-color);
}

.contact-page a {
  color: var(--font-color);
}

.contact-page h2 {
  margin-top: 15vh;

  opacity: 0;
  transform: translateY(100%);
}

.contact-page.active h2 {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}


.contact-info {
  width: 50%;
  height: 25vh;

  display: flex;
  justify-content: space-between;

  opacity: 0;
  transform: translateY(40%);
}

.contact-page.active .contact-info {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

.contact-info ul:first-child {
  border-right: 2px solid var(--background-sec-color);
}

.contact-info ul {
  width: 50%;
  height: inherit;
  list-style: none;

  font-size: 1.2em;
  color: var(--font-color);

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info span {
  display: block;
  font-style: bold;
  font-size: 1.45em;
}

.contact-info ul li {
  margin-bottom: 25px;
  padding-left: 25px;
}

.map-container {
  height: 40vh;
  width: 100%;

  opacity: 0;
  transform: translateY(40%);
}

.contact-page.active .map-container {
  opacity: 1;
  transform: translateY(0%);
  transition: 1s ease-out;
}

.marker {
  width:0;
  height:0;
}

.marker  span {
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width: 30px;
  height: 30px;
  color:#fff;
  background: #8c52d4;
  border:solid 2px;
  border-radius: 0 70% 70%;
  box-shadow:0 0 2px #000;
  cursor: pointer;
  transform-origin:0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg)
}

.marker span {
  background: #8c52d4;
}

@media screen and (max-width: 1525px) {
  .history-tab-container {
    width: 85vw;
  }
}

@media screen and (max-width: 1250px) {
  .tabs h3 {
    margin: 0 7px;
    margin-bottom: 5px;
  }

  .tabs h4 {
    margin-bottom: 5px;
  }

  .nav-menu {
    width: 60%;
    margin: 0 20px;
  }

  .contact-info {
    width: 60%;
  }
}

@media screen and (max-width: 840px) {
  /* ---------------------------- Contact Page ---------------------------- */

  .contact-info {
    width: 80%;
  }
}

@media screen and (max-width: 950px) {
  /* Navigation */

  body {
    overflow-x: hidden;
  }

  nav .zigzag {
    display: none;
  }

  nav .sun {
    display: none;
  }

  nav {
    display: flex;
    justify-content: space-between;
    background-color: var(--nav-background-color);
    border-bottom: 2px solid var(--background-sec-color);
  }

  .nav-border {
    height: 0px;
  }

  .nav-menu {
    position: fixed;
    right: 0px;
    margin: 0px;
    top: var(--nav-height);
    background-color: var(--background-sec-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease;
  }

  .nav-menu.active {
    transform: translateX(0%);
  }

  .nav-menu li a {
    display: table;
    text-align: center;
    padding: 1.5rem;
    margin: 0px;
    width: 100%;
    border-bottom: solid var(--background-color) 1px;
  }

  nav a {
    margin-left: 30px;
  }

  nav img {
    display: block;
    max-height: calc(var(--nav-height) * 0.5);
    height: 50px;
  }

  .burger {
    display: block;
    margin-right: 30px;
  }

  .burger.active div:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }
  
  .burger.active div:nth-child(2) {
    opacity: 0;
  }
  
  .burger.active div:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }

  /* Home Page */

  .homepage img {
    margin-top: 35vh;
    max-width: 50vw;
  }

  .scroll-down .line {
    width: 18vw;
  }

  .homepage a {
    margin-bottom: 15vh;
  }

  /* History Tab Page */

  .history-tab-page {
    height: auto;
  }

  .tab-content {
    width: 100%;
    align-items: stretch;

    border-bottom: 2px solid var(--background-color);
  }

  .history-tab-container {
    flex-direction: column;
    height: auto;
    margin-bottom: 5vh;
  }

  .tab-content .content {
    height: 40vh;
    flex-direction: column;

    height: auto;

  }

  .tab-content h3 {
    font-size: 1.5em;
  }

  .tab-content h4 {
    font-size: 1.25em;
  }

  .tab-content .content img {
    margin-top: 20px;
    height: 40vh;
  }

  .tab-content .content p {
    font-size: 1.05em;
  }


  /* Gallery */
  .gallery-page {
    height: auto;
  }

  .image-container {
    margin-bottom: 50px;
  }
  
  .image-wrapper {
    width: 23vw;
  }
}

@media screen and (max-width: 730px) {

  /* History Page */

  .tab-content .content {
    flex-direction: column;
    height: auto;
  }

  .tab-content .content img {
    margin-top: 20px;
    height: 30vh;
  }

  /* Gallery Page */
  .image-container {
    width: auto;
  }

  .image-wrapper {
    width: 40vw;
  }

  .modal img {
    max-width: 80%;
  }

  .img-back {
    margin-left: 0px;
  }
  
  .img-forward {
    margin-right: 0px;
  }

  .modal button {
    height: 40px;
    width: 40px;
  }

  .modal svg:hover {
    color: var(--sec-font-color);
  }
}

@media screen and (max-width: 630px) {
  .homepage img {
    max-width: 65vw;
  }

  .contact-page {
    align-items: center;
    justify-content: center;
  }

  .contact-info {
    margin-top: 18px;
    flex-direction: column;
    text-align: center;

  }

  .contact-info ul {
    width: 100%;
    align-items: center;
    font-size: 1.1em;
  }

  .contact-info ul:first-child {
    border-right: none;
  }

  .contact-info ul li {
    padding-left: 0px;
    margin-bottom: 8px;
  }

  .contact-info span {
    display: block;
    font-size: 1.35em;
  }

  .map-container {
    height: 35vh;
    margin-top: auto;
  }
}

@media screen and (max-height: 800px) {
  .aboutpage {
    justify-content: center;
  }

  h2 {
    font-size: 2em;
    margin-top: 100px;
    margin-bottom: 10px;
  }

  .aboutpage h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }

  .aboutpage p {
    font-size: 1.1em;
    margin-bottom: 20px;
  }

  .contact-page span {
    font-size: 1.3em;
  }

  .contact-page li {
    font-size: 0.95em;
  }

  .contact-page .map-container {
    height: 30vh;
  }

  .contact-info {
    margin-top: 0;
  }
}